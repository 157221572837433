import React from 'react';
import './styles.scss';

const TeamPercent = (props) => {
    return (
        <div className='triviaContainer'>
            <div className='TeamPercentWrap'>
                <div className='TeamPercentInner'>
                    {props.data.map((data, index) => {
                        return (
                            <div className='TeamPercentCard' key={index}>
                                <h3>{data.Header}</h3>
                                <p>{data.Desc}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default TeamPercent;
