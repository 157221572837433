import React from 'react';
import './styles.scss';

const OrganizationCard = (props) => {
    return (
        <div className='triviaContainer'>
            <div className='OrganizationCardHeader'>
                <h3>Thousands of organizations trust Trivia to engage their teams</h3>
            </div>
            <div className='OrganizationCardWrap'>
                {props.data.map((data, index) => {
                    return (
                        <div className='OrganizationCardInner'>
                            <div className='OrganizationCard' key={index}>
                                <img data-src={data.Image} alt={''} />
                                <div className='OrganizationBottom'>
                                    <div className='OrganizationDiv'>
                                        <span>
                                            <img
                                                data-src='https://sw-assets.gumlet.io/trivia/employee-engagement/code.png'
                                                alt={''}
                                            />
                                        </span>
                                        <p>{data.Desc}</p>
                                    </div>
                                    <h6>
                                        <span>{data.name}</span> | {data.designation}
                                    </h6>
                                    <p>
                                        <img
                                            data-src='https://sw-assets.gumlet.io/trivia/employee-engagement/triangle.png'
                                            alt={''}
                                        />{' '}
                                        {data.statement}
                                    </p>
                                    <p>
                                        <img
                                            data-src='https://sw-assets.gumlet.io/trivia/employee-engagement/triangle.png'
                                            alt={''}
                                        />{' '}
                                        {data.statementTwo}
                                    </p>
                                    <a href={data.link} target={'_blank'} rel={'noopener noreferrer'}>
                                        Read now{' '}
                                        <img
                                            data-src='https://sw-assets.gumlet.io/trivia/employee-engagement/arrow.png'
                                            alt={''}
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default OrganizationCard;
